import React from 'react'

const Price = (data) => {
    return(
        <div className={`showroom--price-block${data.text ? " text" : ""}`}>
            <div>
                <h4>{data.top}</h4>
                {data.text && typeof data.text === "string" ? <p className="showroom--price-title">{data.text}</p> : data.text}
            </div>
            <div>
                <p className="showroom--price"><span style={{fontSize: '40%'}}>kr.</span> {data.price}</p>
                {data.priceText && typeof data.priceText === "string" ? <p className="showroom--price-title">{data.priceText}</p> : data.priceText}
                {data.incl && <p className="showroom--price-incl">{`inkl. ${data.incl}`}{data.extra && <sup>{data.extra}</sup>}</p>}
            </div>
        </div>
    )
}

export default Price