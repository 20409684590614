import React from 'react'

const Input = (data) => {
    const required = data.required ? true : false
    let idFor 
    if(data.type === "radio") {
        let extra = data.other.value.replace(" ", "-").toLowerCase(); 
        idFor = `input-${data.name}-${extra}`
    } else {
        idFor = `input-${data.name}`
    }

    return(
        <div className="mb--input-block">
            <label htmlFor={idFor} className={required ? "required" : ""}>
                <input type={data.type} id={idFor} name={data.name} required={required} {...data.other} />
                <span>{data.children}</span>
            </label>
            
        </div>
    )
}

export default Input