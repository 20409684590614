import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Img from 'gatsby-image'
import "../styles/showroom.css"
import "../styles/about.css"
import Price from "../components/Price"
import Input from "../components/form/Input";
import Slider from "react-slick";

export default ({ data }) => {
    const sliderOptions = {
        variableWidth: true,
        slidesToShow: 1,
        infinite: false,
        responsive: [{
            breakpoint: 900,
            settings: {
              slidesToShow: 1,
              centerMode: false,
              variableWidth: false,
              arrows: true
            }
        },
        {
          breakpoint: 600,
          settings: {
            variableWidth: false,
            slidesToShow: 1,
            arrows: true
          }
        }]
      }
    
  return (
    <>
        <SEO title={"Pro-simulator"} />
        <section id="showroom">
            <h2 className="heading">Pro-simulator</h2>
            <Img fluid={data.directusProSimulator.main_image.localFile.childImageSharp.fluid} className="opaque50 about--image-top" />
            {data.directusProSimulator.catch_text.length > 0 && <p className="about--catch">{data.directusProSimulator.catch_text}</p>}
            <div className="opaque80 about--text" dangerouslySetInnerHTML={{__html: data.directusProSimulator.text}}></div>
        </section>
            {data.directusProSimulator.gallery !== null && (
            <section className="news--gallery">
                <Slider {...sliderOptions} className="news--gallery-slider">
                    <div style={{
                        height: ((data.directusProSimulator.youtube_video.height / data.directusProSimulator.youtube_video.width ) * 40 ) + "rem",
                        width: '40rem',
                        display: 'block',
                        paddingRight: '1rem'
                    }}>
                        {data.directusProSimulator.youtube_video.embed.length > 0 && <iframe 
                            src={`https://www.youtube.com/embed/${data.directusProSimulator.youtube_video.embed}`}
                            frameBorder={0}
                            style={{
                                width: '100%',
                                padding: '0 1rem',
                                height: ((data.directusProSimulator.youtube_video.height / data.directusProSimulator.youtube_video.width ) * 40 ) + "rem",
                            }}
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            webkitallowfullscreen="true"
                            mozallowfullscreen="true"
                            allowFullScreen={true}
                            title={data.directusProSimulator.youtube_video.title}
                        />}
                    </div>
                    {data.directusProSimulator.gallery.map((item, index) => {
                        return(
                        <figure className="news--gallery-item" key={index} style={{
                            width: 'auto'
                        }}>
                            <Img fluid={item.image.localFile.childImageSharp.fluid}/>
                            {item.name !== null && (
                            <figcaption>{item.name}</figcaption>
                            )}
                        </figure>
                        )
                    })}
                </Slider>
            </section>
            )}
        <section className="showroom">
            <section className="showroom--prices-container">
                <h3>Priser</h3>
                <section className="showroom--prices">
                    <Price top="1 time fra" price={data.directusProSimulator.pris} priceText=" + moms pr. person" incl="instruktør"/>
                    <Price top="Basic pakke (min. 2 timer)" price={`${data.directusProSimulator.pris}`} priceText=" + moms pr. time pr. person inkl. instruktør" text="Gennemgang af Pro-sim samt skræddersyet coachingforløb tilpasset kørers individuelle behov."/>
                </section>
            </section>
            <section className="showroom--contact-block">
                <h3>Kontakt os</h3>
                <form data-netlify="true" method="POST" name="Pro-simulator" netlify-honeypot="bot-field" netlify="true" action="/thank-you">
                    <div>
                        <p style={{visibility: 'hidden', position: 'absolute'}}>
                            <label htmlFor="bot">Don’t fill this out if you're human: <input id="bot" name="bot-field" aria-label="Don’t fill this out if you're human:"/></label>
                        </p>
                        <Input name="name" type="text" required={true}>Dit navn</Input>
                        <Input name="email" type="email" required={true}>Din emailadresse</Input>
                        <Input name="phone" type="text" required={true}>Dit telefonnummer</Input>
                        <Input name="virksomhed" type="text">Virksomhed</Input>
                    </div>
                    <div>
                        <div className="horizontal">
                            <Input name="date" type="date">Dato</Input>
                            <Input name="person" type="number" other={{min: "1"}}>Antal personer</Input>
                            <Input name="hours" type="number" other={{min: "1"}}>Antal timer pr. person</Input>
                        </div>
                        <div className="mb--input-block">
                            <label htmlFor="comments">Ekstra kommentarer<textarea name="text" id="comments" rows="8" aria-label="Ekstra kommentarer"></textarea></label>
                        </div>
                    </div>
                    <input type="hidden" name="form-name" value="showroom" aria-label="Hidden: Showroom" />
                    <button type="submit">SEND</button>


                </form>
            </section>
        </section>


    </>
  )
}

    export const query = graphql`
    query {
        directusProSimulator {
            catch_text
            main_image {
                localFile {
                    childImageSharp {
                        fluid(quality: 70) {
                            ...GatsbyImageSharpFluid_withWebp            
                        }
                    }
                }
            }
            text
            gallery {
                name
                image {
                    localFile {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 500) {
                                ...GatsbyImageSharpFluid_withWebp            
                            }
                        }
                    }
                }
            }
            pris
            pris_extra
            youtube_video {
                embed
                title
                width
                height
            }
        }
    }
`
